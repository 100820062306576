module.exports = [{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-canonical-urls@5.14.0_gatsby@5.14.1_@types+webpack@5.28.5_babel-eslint@10.1.0_e_pwgg77vqg4hxyshd4kjb3be5oe/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.easydatasolution.de/","stripQueryString":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-decap-cms@4.0.4_webpack@5.98.0/node_modules/gatsby-plugin-decap-cms/gatsby-browser.js'),
      options: {"plugins":[],"modulePath":"/home/runner/work/easydatasolution/easydatasolution/apps/homepage/src/cms/cms.ts","enableIdentityWidget":true,"publicPath":"admin","manualInit":true},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby-plugin-manifest@5.14.0_gatsby@5.14.1_@types+webpack@5.28.5_babel-eslint@10.1.0_eslint@_w372wkcre4s64hlgkxxl6dejle/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"easy data solution","short_name":"easydatasolution","lang":"de","start_url":"/","background_color":"#FFFFFF","theme_color":"#ee7128","display":"standalone","icon":"/home/runner/work/easydatasolution/easydatasolution/apps/homepage/src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"c63f1b1d0f33bfddd09b0aeddb5b1d1e"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../../node_modules/.pnpm/gatsby@5.14.1_@types+webpack@5.28.5_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react_kxjykf2r6r52h25kb2dfpf3tma/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
