import type { GatsbyBrowser, ShouldUpdateScrollArgs } from 'gatsby';

import { RootProvider } from './src/root-provider';

import './src/styles/global.css';

import '@easydatasolution/config-tailwind/styles.css';
import '@fontsource/open-sans/latin-ext.css';

export const wrapRootElement: GatsbyBrowser['wrapRootElement'] = RootProvider;

export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ prevLocation }) => {
	if (process.env.NODE_ENV === `production` && typeof window.plausible !== `undefined`) {
		window.plausible(`pageview`);
	}

	window.previousLocation = prevLocation;
};

export const shouldUpdateScroll: GatsbyBrowser['shouldUpdateScroll'] = ({
	routerProps: { location },
}: ShouldUpdateScrollArgs) => {
	// use navigate's state {noScroll: true} to prevent scrolling to the top
	if (location.state?.noScroll) {
		return false;
	}

	// Otherwise scrolling to a section is broken
	if (location.hash) {
		return true;
	}

	// Always explicitly handle scroll position
	// otherwise it doesn't seem to work in Firefox
	requestAnimationFrame(() => {
		window.scrollTo({
			top: 0,
			left: 0,
			behavior: 'instant',
		});
	});

	return false;
};
