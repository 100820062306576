exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-buchung-erfolgreich-jsx": () => import("./../../../src/pages/buchung/erfolgreich.jsx" /* webpackChunkName: "component---src-pages-buchung-erfolgreich-jsx" */),
  "component---src-pages-buchung-index-jsx": () => import("./../../../src/pages/buchung/index.jsx" /* webpackChunkName: "component---src-pages-buchung-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-kurse-firmenschulungen-tsx": () => import("./../../../src/pages/kurse/firmenschulungen.tsx" /* webpackChunkName: "component---src-pages-kurse-firmenschulungen-tsx" */),
  "component---src-pages-referenzen-jsx": () => import("./../../../src/pages/referenzen.jsx" /* webpackChunkName: "component---src-pages-referenzen-jsx" */),
  "component---src-pages-ueber-uns-jsx": () => import("./../../../src/pages/ueber-uns.jsx" /* webpackChunkName: "component---src-pages-ueber-uns-jsx" */),
  "component---src-pages-warenkorb-tsx": () => import("./../../../src/pages/warenkorb.tsx" /* webpackChunkName: "component---src-pages-warenkorb-tsx" */),
  "component---src-templates-blog-post-jsx": () => import("./../../../src/templates/blogPost.jsx" /* webpackChunkName: "component---src-templates-blog-post-jsx" */),
  "component---src-templates-course-tsx": () => import("./../../../src/templates/course.tsx" /* webpackChunkName: "component---src-templates-course-tsx" */),
  "component---src-templates-course-type-tsx": () => import("./../../../src/templates/courseType.tsx" /* webpackChunkName: "component---src-templates-course-type-tsx" */),
  "component---src-templates-digitalization-tsx": () => import("./../../../src/templates/digitalization.tsx" /* webpackChunkName: "component---src-templates-digitalization-tsx" */),
  "component---src-templates-legal-text-jsx": () => import("./../../../src/templates/legalText.jsx" /* webpackChunkName: "component---src-templates-legal-text-jsx" */)
}

