import { extendTheme } from '@chakra-ui/react';

const additionalSizesAndSpacing = {
	72: '18rem',
	80: '20rem',
	88: '22rem',
};

export const theme = extendTheme({
	styles: {
		global: {
			pre: {
				fontFamily: 'body',
			},
		},
	},
	breakpoints: {
		base: '0px',
		sm: '480px',
		md: '768px',
		lg: '992px',
		xl: '1280px',
		'2xl': '1408px',
	},
	colors: {
		// https://www.0to255.com/ee7128 (ee7128 is desired)
		primary: {
			50: '#fce3d5',
			100: '#f8c4a6',
			200: '#f5af86',
			300: '#f39b67',
			400: '#f08647',
			500: '#ee7128',
			600: '#e25f12',
			700: '#c3510f',
			800: '#a3440d',
			900: '#84370a',
		},
		// https://www.0to255.com/369cc0 (184656 is desired)
		secondary: {
			50: '#EBF6F9',
			100: '#C7E5EF',
			200: '#A3D4E5',
			300: '#80C3DB',
			400: '#5CB2D1',
			500: '#38A2C7',
			600: '#2D819F',
			700: '#226177',
			800: '#164150',
			900: '#0B2028',
		},
		// https://www.0to255.com/a4373a
		access: {
			50: '#faf1f1', // +16
			100: '#f2d7d8', // + 14
			300: '#c7585b', // + 4
			500: '#a4373a',
			600: '#8b2e31', // -2
		},
		excel: {
			50: '#eefaf3',
			100: '#ace7c6',
			300: '#30a866',
			500: '#217346',
			600: '#195936',
		},
		outlook: {
			50: '#e8f5ff',
			100: '#93d1ff',
			300: '#0b97ff',
			500: '#0072c6',
			600: '#005ea4',
		},
		powerpoint: {
			50: '#fbeeea',
			100: '#f1bdb1',
			300: '#E1755B',
			500: '#D24726',
			600: '#b53d21',
		},
		project: {
			50: '#eff8ef',
			100: '#bfe5be',
			300: '#45A642',
			500: '#31752F',
			600: '#275d25',
		},
		visio: {
			50: '#f1f4fa',
			100: '#bfcae9',
			300: '#5B77C5',
			500: '#3955A3',
			600: '#30488a',
		},
		word: {
			50: '#ecf1fa',
			100: '#b7cceb',
			300: '#5B77C5',
			500: '#2B579A',
			600: '#24487f',
		},
		teams: {
			50: '#ededf5',
			100: '#d7d7e9',
			300: '#8081b8',
			500: '#57599d',
			600: '#4b4d87',
		},
		powerbi: {
			50: '#faf2e7',
			100: '#f4e5cf',
			300: '#F0A22B',
			500: '#C97F0E',
			600: '#b5720d',
		},
		powerautomate: {
			50: '#eef5ff',
			100: '#cce0ff',
			300: '#448fff',
			500: '#06f',
			600: '#0058dd',
		},
		powerapps: {
			50: '#fbf3fb',
			100: '#ebc0e7',
			300: '#c33eb7',
			500: '#902d87',
			600: '#76256f',
		},
		// only 50 + 500 seem to be used
		künstlicheintelligenz: {
			50: '#f2fbff',
			100: '#72aec9',
			300: '#35718a',
			500: '#1B5A72',
			600: '#0b4f66',
		},
	},
	fonts: {
		heading: 'Open Sans, -apple-system, sans-serif',
		body: 'Open Sans, -apple-system, sans-serif',
	},
	spacing: {
		...additionalSizesAndSpacing,
	},
	sizes: {
		...additionalSizesAndSpacing,
		120: '30rem',
	},
});
